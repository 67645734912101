<template>
    <div class="dashboard">
        <div class="wrapper">
            <div class="h1">Список администраторов</div>
            <b-table
                :busy="isBusy"
                :fields="fields"
                :items="adminsProvider"
                class="mb-0"
                :current-page="currentPage"
                :per-page="perPage"
                empty-text="Нет доступных пользователей"
                show-empty
                ref="adminTable"
                @row-clicked="showUserDetails"
                hover
            >
                <template #cell(lastName)="data">
                    <div class="user-td">
                        <img v-if="data.item.avatarPath" :src="data.item.avatarPath" alt="">
                        <div class="user-name">
                            {{data.item.lastName}}
                            {{data.item.firstName}}
                            {{data.item.patroName}}
                        </div>
                    </div>
                </template>

                <template #cell(email)="data">
                    {{data.item.email ? data.item.email.address : '-'}}
                </template>

                <template #cell(delete)="data">
                    <button class="btn-crl" @click="handleUserDelete(data.item._id)">
                        <b-icon icon="x-circle-fill"></b-icon>
                    </button>
                </template>

                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                </template>

                <template #empty="scope">
                    <div class="text-center my-2">
                        {{ scope.emptyText }}
                    </div>
                </template>

                <template #emptyfiltered="scope">
                    <div class="text-center my-2">
                        {{ scope.emptyText }}
                    </div>
                </template>
            </b-table>
        </div>

        <div v-if="totalAdmins > perPage" class="pt-3">
            <b-pagination first-number last-number :total-rows="totalAdmins" :per-page="perPage" v-model="currentPage"/>
        </div>

        <button
            v-b-modal.add-user
            class="add"
        >
            <b-icon icon="plus-circle"></b-icon>
        </button>

        <AddUser :isAdmin="true" @addUser="addUser"/>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import AddUser from "../components/modals/AddUser";
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'

    export default {
        name: "admins",
        components: {AddUser},
        methods: {
            ...mapActions([
                'getUsers',
                'addNewUser',
                'setUserRole',
                'deleteUser'
            ]),
            showUserDetails(user){
                this.$router.push(
                    {
                        name: 'user-details',
                        params: {
                            userId: user._id,
                            from: 'admins'
                        }
                    }
                )
            },
            addUser(user){
                const data = {
                    ...user,
                    language: user.language.lang
                };
                this.addNewUser(data).then(userApi => {
                    const userRoleData = {
                        userId: userApi._id,
                        role: 'ADMIN'
                    };
                    this.setUserRole(userRoleData).then(userRoleApi => {
                        this.admins.push(userRoleApi);
                        this.$refs.adminTable.refresh();
                        this.$bvModal.hide('add-user');
                    })
                })
                    .catch(error => {
                        errorsAlertApi(error);
                    });
            },
            adminsProvider(ctx){
                const data = {
                    page: ctx.currentPage,
                    searchBy: 'role',
                    search: 'ADMIN'
                };

                return this.getUsers(data).then(users => {
                    this.totalAdmins = users.totalCount;
                    this.admins = users.results;
                    return this.admins;
                })
                    .catch(() => {
                        return []
                    })
            },
            handleUserDelete(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить администратора?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteUser(id).then(() => {
                            this.$refs.adminTable.refresh();
                        })
                            .catch(error => {
                                errorsAlertApi(error);
                            })
                    }
                })
            }
        },
        data(){
            return {
                admins: [],
                isBusy: false,
                fields: [
                    {
                        key: 'lastName',
                        label: 'ФИО',
                        sortable: false,
                        tdClass: 'w-25'
                    }, {
                        key: 'email',
                        label: 'E-mail',
                        sortable: false
                    }, {
                        key: 'delete',
                        label: '',
                        sortable: false
                    }
                ],
                currentPage: 1,
                perPage: 10,
                totalAdmins: 0,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dashboard{
        padding-bottom: 40px;
    }
    .user{
        &-td {
            display: flex;
            align-items: center;
            min-height: 48px;
            img {
                width: 48px;
                height: 48px;
                margin-right: 10px;
                border-radius: 50%;
                overflow: hidden;
                object-fit: cover;
                flex: none;
            }
        }
    }
</style>